import { Navigate } from 'react-router-dom';
import PropTypes from "prop-types";

const PrivateRoute = ({ child, user }) => {

    if(!user) // no token/user in localStorage => go back to root
        return <Navigate to="/" replace />;   
        
    return child;
};

PrivateRoute.propTypes = {
    child: PropTypes.object,
    user: PropTypes.object
};

export default PrivateRoute;
