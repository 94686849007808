import {GameRoles, SysAdminPermissions} from "../constants/roles.js";
import * as PermissionsUtils from "../utils/PermissionsUtils.js";

export default class UserDto {
    constructor(email, displayName, systemAdmin, games) {
        this.email = email;
        this.displayName = displayName;
        this.systemAdmin = systemAdmin;
        this.games = games;
    }

    static create(obj){
        return new UserDto(obj.email, obj.displayName, obj.systemAdmin, obj.games);
    }

    isSystemAdmin() {
        return this.systemAdmin;
    }

    hasOneOfThePermissions(permissions, game, env) {
        return PermissionsUtils.hasOneOfThePermissions(this, permissions, game, env);
    }

    hasPermission(permission, game, env) {
        return PermissionsUtils.hasPermission(this, permission, game, env);
    }

    getPermissions(game, env) {
        let permissions = [];

        const userGameRoles = this.games.find(x => x.key === game)?.roles;

        if (userGameRoles) {
            const rolesPermissions = userGameRoles
                .filter(userGameRole => userGameRole.role.environments.includes(env))
                .flatMap(userGameRole => GameRoles[userGameRole.role].permissions);
            permissions = permissions.concat(rolesPermissions);
        }

        if (this.systemAdmin) {
            permissions = permissions.concat(...Object.keys(SysAdminPermissions));
        }

        return permissions;
    }
}