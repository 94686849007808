import * as React from 'react';
import { Grid, Button, Box, List } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import Header from "./header";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from "prop-types";
import { getInnerElement } from '../utils/componentHelper';

const Compound = ({feature, featureValues, colors, backdropOpened, externalHandleChange, editMode}) => {
    if(!featureValues) // protect from null ref
        featureValues = [];

    const [pressed, setPressed] = React.useState(false);
    const [values, setValues] = React.useState(featureValues);
    //React.useEffect(() => setValues(featureValues), [featureValues]);

    const setSingleValue = (values, setValues, fieldId) => {
        return (fieldValue) => {
            
            let obj = values.find(x => x.id === fieldId);
            if(obj)
                obj.value = fieldValue;
            else
                values.push({id: fieldId, value: fieldValue});
            
            setValues(values);
        };
    };

    let innerProperties = [];
    feature.fields.forEach(field => {
        
        let value = "";
        let featureValue = featureValues.find(x => x.id.toString() === field.id);
        if(featureValue)
            value = featureValue.value;

        innerProperties.push(getInnerElement(field.id, field.type, value, setSingleValue(values, setValues, field.id), 
            colors, field.displayName, field.description, field.options, field.minValue, field.maxValue, false, editMode));
    });

    return (pressed ?
                <Dialog fullWidth={true} onClose={() => {setPressed(false);}} open={pressed}>
                    <Box backgroundColor={colors.primary[400]} display="flex" alignItems="start" justifyContent="space-between">
                        <Header title={`Edit ${feature.displayName}`} subtitle={`${feature.description}`} />
                    </Box>
                    <List disablePadding={true}>
                        {innerProperties}
                    </List>
                    {editMode 
                        ? <Button variant="contained" color="secondary" onClick={() => externalHandleChange(values, () => setPressed(false))}>Save</Button>
                        : <></>}
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={backdropOpened}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Dialog>
                :
                <Grid container justifyContent="end">
                    <Button variant="text" color="secondary" onClick={() => setPressed(true)}>Expand ...</Button>
                </Grid>
            );
};

Compound.propTypes = {
    feature: PropTypes.object,
    featureValues: PropTypes.array,
    colors: PropTypes.object,
    backdropOpened: PropTypes.bool,
    externalHandleChange: PropTypes.func,
    editMode: PropTypes.bool,
    fields: PropTypes.array
};

export default Compound;

