import {Box, Button} from "@mui/material";
import {DataGridPremium, GridToolbar} from "@mui/x-data-grid-premium";
import {tokens} from "../theme";
import Header from "../components/header";
import {useTheme} from "@mui/material";
import {GridActionsCellItem} from "@mui/x-data-grid-premium";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UserForm from "../components/userForm";
import {fetchUsers, deleteUser as externalDeleteUser} from "../utils/network";
import AddIcon from '@mui/icons-material/Add';
import {useEffect, useState} from "react";
import PropTypes from "prop-types";

function getEnvDisplayName(envKey, environments){
    return environments.find(x => x.key === envKey).displayName;
}

const UsersPage = ({games, roles, environments, setBackdropOpened, backdropOpened}) => {

    const [users, setUsers] = useState([]);
    useEffect(() => {
        setBackdropOpened(true);
        fetchUsers((users) => {
            setUsers(users);
            setBackdropOpened(false);
        });
    }, [setUsers, setBackdropOpened]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [editedUserObj, setEditedUserObj] = useState(null);

    const onDialogClose = () => {
        setEditedUserObj(null);
    };

    const onDialogOpen = (userEmail) => {
        setEditedUserObj({'type': 'edit', 'email': userEmail});
        // setEditedUserEmail(userEmail);
        // if (userEmail) {
        //     let user = getUser(users, userEmail);
        //     let cloned = UserDto.create(user);
        //     updateEditedUser(cloned);
        // } else {
        //     updateEditedUser(undefined);
        // }
    };

    const deleteUser = (id) => {
        setBackdropOpened(true);
        externalDeleteUser(id, () => {
            fetchUsers(setUsers);
            setBackdropOpened(false);
        });
    };

    const columns = [
        {
            field: "displayName",
            headerName: "Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "systemAdmin",
            headerName: "Backoffice Admin",
            type: "boolean",
            flex: 0.5
        }
    ];

    if (games) {
        for(const [gameKey, value] of Object.entries(games)){
            columns.push({
                field: value.displayName,
                headerName: value.displayName,
                flex: 1,
                valueGetter: (params) => {
                    const gameData = params.row.games.find(x => x.key === gameKey);
                    return !gameData 
                    ? 'N/A'
                    : gameData.roles.map(role => 
                        { 
                            let gameRole = roles.find(x => x.key === role.role);
                            return `${gameRole.displayName} (${role.environments.map(x => getEnvDisplayName(x, environments)).join(', ')})`;
                        }
                    ).join(' | ');
                }
            });

            columns.push({
                field: `${value.displayName} CID`,
                headerName: `${value.displayName} CID`,
                flex: 1,
                valueGetter: (params) => {
                    const gameData = params.row.games.find(x => x.key === gameKey);
                    return !gameData ? 'N/A' : gameData.userCID;
                }
            });
        }
    }

    columns.push({
        field: "actions",
        type: "actions",
        align: "right",
        headerAlign: "right",
        headerName: "Actions",
        width: 50,
        flex: 0.5,
        getActions: (params) => [
            <GridActionsCellItem key={params.row.email} icon={<EditIcon/>} onClick={() => onDialogOpen(params.row.email)} label="Edit"/>,
            <GridActionsCellItem key={params.row.email} icon={<DeleteIcon/>} onClick={() => deleteUser(params.row.email)} label="Delete"/>
        ]
    });

    const renderForm = () => {
        if(!editedUserObj)
            return null;

        return (<UserForm games={games} roles={roles} environments={environments} user={getUser(editedUserObj, users)} setUsers={setUsers} onDialogClose={onDialogClose}
            backdropOpened={backdropOpened} setBackdropOpened={setBackdropOpened}/>);
        
    };

    function getUser(editedUserObj, users){
        if(editedUserObj && editedUserObj.type === 'edit')
            return users.find(x => x.email === editedUserObj.email);
    }

    return <Box m="20px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="Users" subtitle="List of Internal Backoffice Users"/>
                    <Button
                        onClick={() => onDialogOpen("")}
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px"
                        }}
                    >
                        <AddIcon sx={{mr: "10px"}}/>
                        Create
                    </Button>
                </Box>
                <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .name-column--cell": {
                            color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: colors.blueAccent[700],
                            borderBottom: "none"
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },
                    }}
                >
                    <DataGridPremium
                        getRowId={(row) => row.email}
                        rows={users}
                        columns={columns}
                        slots={{toolbar: GridToolbar}}
                    />
                    {renderForm()}
                </Box>
            </Box>;
};

UsersPage.propTypes = {
    games: PropTypes.object,
    roles: PropTypes.array,
    setBackdropOpened: PropTypes.any,
    backdropOpened: PropTypes.bool,
    environments: PropTypes.array 
};

export default UsersPage;
