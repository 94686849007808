import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Tooltip, Typography, useTheme, Collapse } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import * as React from 'react';
import { getFeatureRouting } from "../utils/network";
import { Environments, isConfig } from "shared";
import { sectionTypes } from "../constants/sectionTypes";
import { getIconElement } from "../utils/icons";
import { Icons } from "shared";
import PropTypes from "prop-types";

const Item = ({ title, to, icon, selected, setSelected, sectionName, setSelectedSection }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let variant = sectionName ? "h6" : "h5";
  return (
    <Tooltip title={title} placement={'bottom-end'}>
        <MenuItem active={selected === title} style={{color: colors.grey[100]}} onClick={() => { 
              setSelected(title); 

              if(setSelectedSection)
                setSelectedSection(sectionName); 
          }} icon={icon}>
          <Typography variant={variant}>{title}</Typography>
          <Link to={to} />
        </MenuItem>
    </Tooltip>
  );
};

const Sidebar = ({gameKey, env, user, complexFeatures, selected, setSelected, selectedSection, setSelectedSection}) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const filteredFeatures =  complexFeatures.filter(x => x.section === sectionTypes.FEATURES);
  const filteredDataGrids = complexFeatures.filter(x => x.section === sectionTypes.DATA);

  const SectionItem = ({ title, icon, items }) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isExpanded = selectedSection === title;
    const [expanded, setExpanded] = useState(isExpanded);

    const handleClick = () => {
      if(selectedSection === title) {
        setExpanded(false);
      }
      else{
        setExpanded(true);
        setSelectedSection(title);

        if(!items.find(x => x.displayName === selected))
          setSelected(null);
      }
    };

    return (
      <Tooltip title={title} placement={'bottom-end'}>
          <MenuItem active={isExpanded} style={{color: colors.grey[100]}} onClick={handleClick} icon={icon}>
            <Typography variant="h5">{title}</Typography>
            <Collapse in={expanded} timeout="auto">
              {items}
            </Collapse>
          </MenuItem>
      </Tooltip>
    );
  };

  SectionItem.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    items: PropTypes.array
  };
  
  const getFeatures = (features) => {
  
    let res = [];
    if(features){
      const map = {};
      const highestLevelNames = [];
      features.forEach(feature => {
        let key = feature.subSection ?? feature.displayName;
        if(map[key] === undefined)
          map[key] = [];
        map[key].push(feature);

        if(!highestLevelNames.includes(key))
          highestLevelNames.push(key);
      });

      highestLevelNames.forEach(sectionName => {
        if(map[sectionName][0].subSection){ // sub-sections

          const sectionItems = [];
          const alreadySeenSubSections = [];
          map[sectionName].forEach(feature => {

            let displayName = isConfig(feature) ? 'Metadata' : feature.displayName;
            if(alreadySeenSubSections.includes(displayName))
              return;

            sectionItems.push(
              <Item
                key={displayName}
                title={displayName}
                to={getFeatureRouting(feature)}
                selected={selected}
                setSelected={setSelected}
                sectionName={sectionName}
                setSelectedSection={setSelectedSection}
              />
            );
            alreadySeenSubSections.push(displayName);
          });

          res.push(
            <SectionItem
              key={sectionName}
              title={sectionName}
              icon={getIconElement(map[sectionName][0].wizardData.iconType)} 
              items={sectionItems} 
            />
          );
        }
        else{
          let feature = map[sectionName][0];
          res.push(
            <Item
              key={feature.id}
              title={feature.displayName}
              to={getFeatureRouting(feature)}
              icon={getIconElement(feature.wizardData.iconType)}
              selected={selected}
              setSelected={setSelected}
              sectionName={null}
              setSelectedSection={setSelectedSection}
            />
          );
        }
      });
    }

    return res;
};

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem onClick={() => setIsCollapsed(!isCollapsed)} icon={isCollapsed ? getIconElement(Icons.MENU) : undefined} style={{margin: "10px 0 20px 0",color: colors.grey[100]}}>
            {!isCollapsed && (<Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                                <Typography variant="h3" color={colors.grey[100]}>Backoffice</Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>{getIconElement(Icons.MENU)}</IconButton>
                              </Box>)}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img alt="game" width="100px" height="100px" src={gameKey ? `/assets/${gameKey}.png` : `/assets/react.png`} style={{ cursor: "pointer", borderRadius: "50%" }}/>
              </Box>
              <Box textAlign="center">
                <Typography variant="h2" color={Environments[env].displayName?.toLowerCase() === 'production' ? colors.redAccent[500] : colors.grey[100]} fontWeight="bold" sx={{ m: "10px 0 0 0" }}>
                  {Environments[env].displayName}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {user?.displayName ?? 'Display Name'}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item title="Dashboard" to="/" icon={getIconElement(Icons.HOME)} selected={selected} setSelected={setSelected}/>
            <Item title="Basic Configurations" to="/basic-configurations" icon={getIconElement(Icons.BASIC_CONFIGS)} selected={selected} setSelected={setSelected}/>
            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>Data</Typography>
            {getFeatures(filteredDataGrids, selected, setSelected)}
            {user?.systemAdmin ? <Item title="Users" to="/users" icon={getIconElement(Icons.USERS)} selected={selected} setSelected={setSelected}/> : <></>}
            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>Features</Typography>
            {getFeatures(filteredFeatures, selected, setSelected)}
            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>Support</Typography>
            <Item title="Basic Actions" to="/basic-support-actions" icon={getIconElement(Icons.SUPPORT)} selected={selected} setSelected={setSelected}/>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

Sidebar.propTypes = {
  gameKey: PropTypes.string,
  env: PropTypes.string,
  user: PropTypes.object,
  complexFeatures: PropTypes.array,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  selectedSection: PropTypes.string,
  setSelectedSection: PropTypes.func,
  showImagesPage: PropTypes.bool
};

Item.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.element,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  sectionName: PropTypes.string,
  setSelectedSection: PropTypes.func
};

export default Sidebar;
