import * as React from 'react';
import { Grid, Button, TextField, FormLabel } from "@mui/material";
import PropTypes from "prop-types";

const TextBox = ({id, currentValue, independent, externalHandleChange, setSingleValue, required, editMode}) => {
  
    const handleChange = (value) => {
        if(editMode && value){
            setValue(value);
            
            if(setSingleValue)
                setSingleValue(value.trim());
        }
    };

    const [value, setValue] = React.useState(currentValue);
    React.useEffect(() => setValue(currentValue), [currentValue]);

    return (<Grid container justifyContent="end">
                <FormLabel required={required}></FormLabel>
                <TextField id={id} key={id} type="text" onChange={(event) => {handleChange(event.target.value);}} value={value} InputLabelProps={{ shrink: false }} />
                {independent && editMode ? <Button variant="contained" color="secondary" onClick={() => { 
                    externalHandleChange(value.trim());
                }}>Save</Button> : []}
            </Grid>);        
};

TextBox.propTypes = {
    id: PropTypes.string,
    currentValue: PropTypes.string,
    independent: PropTypes.bool,
    externalHandleChange: PropTypes.func,
    setSingleValue: PropTypes.func,
    editMode: PropTypes.bool,
    required: PropTypes.bool
};

export default TextBox;