import * as React from 'react';
import { Grid, Button } from "@mui/material";
import PropTypes from "prop-types";

const ActionButton = ({buttonText,independent, externalHandleChange, editMode}) => {
    return (<Grid container justifyContent="end">
                {independent && editMode ? <Button variant="contained" color="secondary" onClick={() => { 
                    externalHandleChange();
                }}>{buttonText}</Button> : []}
            </Grid>);        
};

ActionButton.propTypes = {
    buttonText: PropTypes.string,
    independent: PropTypes.bool,
    externalHandleChange: PropTypes.func,
    editMode: PropTypes.bool,
    required: PropTypes.bool
};

export default ActionButton;
