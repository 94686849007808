import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ConstructionIcon from '@mui/icons-material/Construction';
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import TableBarIcon from '@mui/icons-material/TableBar';
import AttractionsIcon from '@mui/icons-material/Attractions';
import CasinoIcon from '@mui/icons-material/Casino';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InsightsIcon from '@mui/icons-material/Insights';
import LoginIcon from '@mui/icons-material/Login';
import DeleteIcon from '@mui/icons-material/Delete';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SavingsIcon from '@mui/icons-material/Savings';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import MobileOffIcon from '@mui/icons-material/MobileOff';
import UndoIcon from '@mui/icons-material/Undo';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import GroupIcon from '@mui/icons-material/Group';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import TaskIcon from '@mui/icons-material/Task';
import ElevatorIcon from '@mui/icons-material/Elevator';
import ExposureIcon from '@mui/icons-material/Exposure';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import SportsFootballOutlinedIcon from '@mui/icons-material/SportsFootballOutlined';
import DoorSlidingRoundedIcon from '@mui/icons-material/DoorSlidingRounded';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';

import { Icons } from 'shared';

const featureIconTypes = [
    { key: Icons.SUPPORT, value: <ConstructionIcon /> },
    { key: Icons.TABLE, value:  <TableBarIcon />},
    { key: Icons.BASIC_CONFIGS, value: <AppShortcutIcon /> },
    { key: Icons.WHEEL, value:  <AttractionsIcon /> },
    { key: Icons.NUMBER, value: <RepeatOneIcon /> },
    { key: Icons.CASINO, value: <CasinoIcon /> },
    { key: Icons.CALENDAR, value: <CalendarMonthIcon /> },
    { key: Icons.NUMBERED_LIST, value: <FormatListNumberedRtlIcon /> },
    { key: Icons.DEFAULT, value: <BarChartOutlinedIcon /> },
    { key: Icons.PEOPLE, value: <PeopleIcon /> },
    { key: Icons.SHOPPING_CART, value: <ShoppingCartIcon /> },
    { key: Icons.STATS, value: <InsightsIcon /> },
    { key: Icons.LOGIN, value: <LoginIcon /> },
    { key: Icons.DELETE, value: <DeleteIcon /> },
    { key: Icons.HOME, value: <HomeOutlinedIcon /> },
    { key: Icons.PEOPLE_OUTLINED, value: <PeopleOutlinedIcon/> },
    { key: Icons.USERS, value: <ContactsOutlinedIcon /> },
    { key: Icons.MENU, value: <MenuOutlinedIcon /> },
    { key: Icons.DEVICE, value: <DeviceUnknownIcon /> },
    { key: Icons.DOLLAR_WITH_CIRCLE, value: <CurrencyExchangeIcon /> },
    { key: Icons.PIG, value: <SavingsIcon /> },
    { key: Icons.BALANCE, value: <CardGiftcardIcon /> },
    { key: Icons.DELETED_DEVICE, value: <MobileOffIcon/> },
    { key: Icons.UNDO, value: <UndoIcon/> },
    { key: Icons.FLAG, value: <SportsScoreIcon/> },
    { key: Icons.PLAYERS, value: <GroupIcon/> },
    { key: Icons.MINUS_PLAYERS, value: <GroupRemoveIcon/> },
    { key: Icons.CAROUSEL, value: <ViewCarouselIcon/> },
    { key: Icons.TASK, value: <TaskIcon/> },
    { key: Icons.ELEVATOR, value: <ElevatorIcon/> },
    { key: Icons.PLUS_MINUS, value: <ExposureIcon/> },
    { key: Icons.IMAGES, value: <PhotoLibraryIcon/> },
    { key: Icons.FOOTBALL, value: <SportsFootballOutlinedIcon/> },
    { key: Icons.DOOR, value: <DoorSlidingRoundedIcon/> },
    { key: Icons.DOLLAR, value: <AttachMoneyIcon/>},
    { key: Icons.VIDEO_SETTINGS, value: <VideoSettingsIcon/>},
];

export const getIconElement = (iconType) => {
    return featureIconTypes.find(x => x.key === iconType)?.value ?? featureIconTypes.find(x => x.key === Icons.DEFAULT).value;
};