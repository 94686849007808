import { Box } from "@mui/material";
import Header from "../components/header";
import * as React from 'react';

const Dashboard = () => {
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Communix Backoffice" subtitle="Welcome to your dashboard" />
      </Box>

      
    </Box>
  );
};

export default Dashboard;