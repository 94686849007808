import {Box, IconButton, Menu, Typography, useTheme} from "@mui/material";
import {useContext} from "react";
import {ColorModeContext, DARK, tokens} from "../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import * as React from 'react';
import {MenuItem} from "react-pro-sidebar";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import {apiCall} from "../utils/network";
import {Games as allGames, Environments} from "shared";
import {Tooltip} from "@mui/material";
import {deleteGameKeyFromStorage, deleteUserAndAuthFromLocalStorage} from "../utils/localStorage";
import PropTypes from "prop-types";

const Topbar = ({env, setEnv, gameKey, setGameKey, user, setStartupData, setBackdropOpened}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    // Env Menu
    const [envMenuAnchorEl, setEnvMenuAnchorEl] = React.useState(false);

    const handleEnvMenuOpen = (event) => {
        setEnvMenuAnchorEl(event.currentTarget);
    };

    const handleEnvMenuClose = async (value) => {
        try {
            setEnvMenuAnchorEl(null);

            if (Environments[value] && value !== env) {
                setBackdropOpened(true);
                setEnv(value);
                const data = await apiCall({
                    method: 'GET',
                    api: "startup",
                    gameKey,
                    environment: value
                });
                setStartupData(data);
                setBackdropOpened(false);
            }
        }
        catch (e) {
            setBackdropOpened(false);
        }
    };

    const getEnvironmentsDropdown = () => {
        let res = [];
        Object.keys(Environments).forEach(envKey => {
            const env = Environments[envKey];
            if (!env.isProd || process.env.REACT_APP_ALLOW_PROD_ACCESS.toLowerCase() === "true") {
                res.push(<MenuItem key={envKey} style={{border: "1px solid"}} onClick={() => {
                    handleEnvMenuClose(envKey);
                }}>{env.displayName}</MenuItem>);
            }
        });
        return res;
    };

    const isEnvMenuOpen = Boolean(envMenuAnchorEl);

    // Game Menu
    const [gameMenuAnchorEl, setGameMenuAnchorEl] = React.useState(false);

    const handleGameMenuOpen = (event) => {
        setGameMenuAnchorEl(event.currentTarget);
    };

    const handleGameMenuClose = async (value) => {
        try {
            setGameMenuAnchorEl(null);

            if (user.games.map(game => game.key).includes(value) && value !== gameKey) {
                setBackdropOpened(true);
                setGameKey(value);
                const data = await apiCall({
                    method: 'GET',
                    api: "startup",
                    gameKey: value,
                    environment: env
                });

                setStartupData(data);
                setBackdropOpened(false);
            }
        }
        catch (e) {
            setBackdropOpened(false);
        }
    };

    const getGamesDropdown = (user) => {
        let res = [];

        user.games.forEach(game => {

            let gameLabel = allGames[game.key].displayName;

            res.push(
                <MenuItem key={game.key} style={{border: "1px solid"}} onClick={() => {
                    handleGameMenuClose(game.key);
                }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <img
                            key={"_" + game.key}
                            alt=""
                            src={`assets/${game.key}.png`}
                            style={{border: "5px", maxWidth: "30%"}}
                            onClick={() => handleGameMenuClose(game.key)}
                        />
                        <Typography variant="body2">{gameLabel}</Typography>
                    </Box>
                </MenuItem>
            );
        });

        return res;
    };

    const isGameMenuOpen = Boolean(gameMenuAnchorEl);

    // Logout
    const handleLogoutClick = () => {
        deleteUserAndAuthFromLocalStorage();
        deleteGameKeyFromStorage();
        window.location.href = '/';
    };

    return (
        <Box sx={{flexGrow: 0}} backgroundColor={colors.primary[400]} position="static" display="flex"
             justifyContent="space-between" p={2}>
            {/* SEARCH BAR */}
            <Box display="flex" backgroundColor={colors.primary[200]} borderRadius="3px">
                {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                <IconButton type="button" sx={{ p: 1 }}>
                <SearchIcon />
                </IconButton> */}
            </Box>

            {/* ICONS */}
            <Box display="flex">
                <Tooltip
                    title={theme.palette.mode === DARK ? "Switch to Light Mode" : "Switch to Dark Mode"}><IconButton
                    onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === DARK ? (<LightModeOutlinedIcon/>) : (<DarkModeOutlinedIcon/>)}
                </IconButton></Tooltip>
                <Tooltip title="Click to choose game"><IconButton
                    onClick={handleGameMenuOpen}><SportsEsportsIcon/></IconButton></Tooltip>
                <Tooltip title="Click to choose environment"><IconButton
                    onClick={handleEnvMenuOpen}><SettingsOutlinedIcon/></IconButton></Tooltip>
                <Tooltip title="Logout"><IconButton onClick={handleLogoutClick}><LogoutIcon/></IconButton></Tooltip>
            </Box>


            <Menu PaperProps={{align: "center", style: {width: "100px"}}}
                  open={isEnvMenuOpen}
                  anchorEl={envMenuAnchorEl}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                  keepMounted
                  onClose={handleEnvMenuClose}
            >
                {getEnvironmentsDropdown()}
            </Menu>

            <Menu PaperProps={{align: "center", style: {width: "100px"}}}
                  open={isGameMenuOpen}
                  anchorEl={gameMenuAnchorEl}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                  keepMounted
                  onClose={handleGameMenuClose}
            >
                {getGamesDropdown(user)}
            </Menu>
        </Box>
    );
};

Topbar.propTypes = {
    env: PropTypes.string,
    setEnv: PropTypes.func,
    gameKey: PropTypes.string,
    setGameKey: PropTypes.func,
    user: PropTypes.object,
    setStartupData: PropTypes.func,
    setBackdropOpened: PropTypes.func
  };

export default Topbar;


