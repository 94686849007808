import * as React from 'react';
import { Grid, Switch } from "@mui/material";
import PropTypes from "prop-types";

const SwitchBox = ({id, currentValue, externalHandleChange, setSingleValue, editMode}) => {
    
    const [checked, setChecked] = React.useState(currentValue);
    React.useEffect(() => setChecked(currentValue), [currentValue]);

    const handleChange = () => {
        if(editMode){
            let opposite = !checked;
    
            if(externalHandleChange)
                externalHandleChange(opposite);
    
            if(setSingleValue)
                setSingleValue(opposite);
    
            setChecked(opposite); 
        }
    };

    return (<Grid container justifyContent="end">
                <Switch id={id} key={id} color="secondary" checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled', 'size': 90 }}/>
            </Grid>);
};

SwitchBox.propTypes = {
    id: PropTypes.string,
    currentValue: PropTypes.bool,
    externalHandleChange: PropTypes.func,
    setSingleValue: PropTypes.func,
    editMode: PropTypes.bool
};

export default SwitchBox;