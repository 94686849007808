export const EnvironmentsKeys = {
    Production: "prod",
    LiveOps: "liveOps",
    QA: "qa",
    Dev: "dev"
}

export const Environments = {
    [EnvironmentsKeys.Production]: {
        displayName: "Production",
        isProd: true
    },
    [EnvironmentsKeys.LiveOps]: {
        displayName: "Live Ops",
        isProd: false
    },
    [EnvironmentsKeys.QA]: {
        displayName: "QA",
        isProd: false
    },
    [EnvironmentsKeys.Dev]: {
        displayName: "Development",
        isProd: false
    }
};