export const GamesKeys = {
    PokerFace: "pokerFace",
    RoyalBingo: "royalBingo"
}

export const Games = {
    [GamesKeys.PokerFace]: {
        displayName: "PokerFace"
    },
    [GamesKeys.RoyalBingo]: {
        displayName: "Royal Bingo"
    }
};

export const getGamesDisplayNames = () => {
    return Object.values(GamesKeys).map(gameKey => Games[gameKey].displayName);
};