import { UserDto } from "shared";

const localStorageEnvKey = `${process.env.NODE_ENV}_envKey`;
const localStorageGameKey = `${process.env.NODE_ENV}_gameKey`;
const localStorageTokenKey = `${process.env.NODE_ENV}_communix_backoffice_token`;
const localStorageUserKey = `${process.env.NODE_ENV}_communix_backoffice_user`;
const localStorageColorModeKey = `communix_backoffice_color_mode`;

export const getGameKeyFromStorage = (user) => {
  if(!user) 
    return null;
  
  let gameKeyFromStorage = localStorage.getItem(localStorageGameKey);

  if(gameKeyFromStorage){
    let gameInUserObject = user.games.find(x => x.key === gameKeyFromStorage);
    if(gameKeyFromStorage){
      if(gameInUserObject){ // OK
        return gameKeyFromStorage;
      } 
      else{
        localStorage.removeItem(localStorageGameKey); // Remove
        return null;
      }
    }
  }

  return null;
};

export const setGameKeyInStorage = (setGameKey, gameKey) => {
  localStorage.setItem(localStorageGameKey, gameKey);
  setGameKey(gameKey);
};

export const deleteGameKeyFromStorage = () => {
    localStorage.removeItem(localStorageGameKey);
};

export const getTokenFromLocalStorage = () => {
    return localStorage.getItem(localStorageTokenKey);
};

export const setUserAndTokenToLocalStorage = (token, user) => {
    localStorage.setItem(localStorageTokenKey, token);
    localStorage.setItem(localStorageUserKey, JSON.stringify(user));
};

export const deleteUserAndAuthFromLocalStorage = () => {
    localStorage.removeItem(localStorageTokenKey);
    localStorage.removeItem(localStorageUserKey);
};

export const getUserFromLocalStorage = () => {
    let user = localStorage.getItem(localStorageUserKey);

    if(user)
        return UserDto.create(JSON.parse(user));

    return null;
};

export const getColorModeFromLocalStorage = () => {
  return localStorage.getItem(localStorageColorModeKey);
};

export const setColorModeInLocalStorage = (colorMode) => {
  localStorage.setItem(localStorageColorModeKey, colorMode);
};

export const getEnvFromLocalStorage = () => {
  return localStorage.getItem(localStorageEnvKey);
};

export const setEnvKeyInLocalStorage = (setEnvKey, envKey) => {
  localStorage.setItem(localStorageEnvKey, envKey);
  setEnvKey(envKey);
};