import { getInnerElement, wrap } from '../utils/componentHelper';
import { updateUser, createNewUser, fetchUsers } from "../utils/network";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Box, List } from "@mui/material";
import Header from "./header";
import Dialog from '@mui/material/Dialog';
import { useTheme } from "@mui/material";
import { tokens } from '../theme';
import { emailValid } from '../utils/string';
import {UserDto} from "shared";
import PropTypes from "prop-types";
import {useState} from "react";
import UserFormRoleEnvPerGame from "./userFormRoleEnvPerGame";

const UserForm = ({games, roles, setUsers, user, environments, onDialogClose, backdropOpened, setBackdropOpened}) => {

    const createMode = user === null || user === undefined;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [data, setData] = useState(user ? UserDto.create(user) : {systemAdmin: false /*default*/});
    
    // false if editedUser has id (enabled in edit mode)
    const [saveDisabled, setSaveDisabled] = useState(true);

    const renderDialogElements = () => {

        const rolesAreValid = (data) => {
          if(data.games === undefined || data.games === null || data.games.length === 0)
            return false;

          for(const game of data.games) {
            
            if(game.roles === undefined || game.roles === null || game.roles.length === 0)
              return false;
            
            let rolesFound = [];
            for(const roleObj of game.roles) {
              if(rolesFound.includes(roleObj.role)){
                return false;
              }
              else{
                rolesFound.push(roleObj.role);
                if(roleObj.role === ''){
                  return false;
                }
                if(roleObj.environments?.length === 0){
                  return false;
                }
              }
            }
          }

          return true;
        };

        const setUserProp = (propName, gameKey, value) => {

          switch(propName){
            case propNames.DISPLAY_NAME:{
              data.displayName = value;
              break;
            }
            case propNames.EMAIL:{
              data.email = value?.trim();
              break;
            }
            case propNames.SYSTEM_ADMIN:{
              data.systemAdmin = value;
              break;
            }
            case propNames.ROLE:
            case propNames.CID:{
              if(!data.games)  
                data.games = [];
              
              let game = data.games.find(x => x.key === gameKey);
              if(!game)
                data.games.push({key: gameKey});

              game = data.games.find(x => x.key === gameKey);
              switch(propName){
                case propNames.CID:
                  game.userCID = value;
                  break;

                case propNames.ROLE:
                  game.roles = value;
                  break;
              }

              break;
            }
            default:{
                break;
            }
          }
          setData(data);

          if(data.displayName && emailValid(data.email) && rolesAreValid(data)){
            setSaveDisabled(false);
          }
          else{
            setSaveDisabled(true);
          }
        };

        let res = [];
        res.push(getInnerElement(propNames.DISPLAY_NAME, "string", user?.displayName ?? '', (value) => setUserProp(propNames.DISPLAY_NAME, null , value), colors, "Name", null, null, null, null, true, true));
        res.push(getInnerElement(propNames.EMAIL, "string", user?.email ?? '', (value) => setUserProp(propNames.EMAIL, null, value), colors, "Email", null, null, null, null, true, createMode));
        
        res.push(getInnerElement(propNames.SYSTEM_ADMIN, "bool", user?.systemAdmin ?? false, (value) => setUserProp(propNames.SYSTEM_ADMIN, null, value), colors, "System Admin", null, null, null, null, true, true));
        
        Object.keys(games).forEach(gameKey => {
            res.push(res.push(wrap(<UserFormRoleEnvPerGame user={user} setUserProp={setUserProp} gameKey={gameKey} allRoles={roles} allEnvironments={environments} />, colors, `${gameKey}_roles`, `${games[gameKey].displayName} Roles`, null)));
            res.push(getInnerElement(`${gameKey}_${propNames.CID}`, "string", user?.games?.find(x => x.key === gameKey)?.userCID ?? '', (value) => setUserProp(propNames.CID, gameKey, value), colors, `${games[gameKey].displayName} CID`, null, null, null, null, false, true));
        });

        return res;
    };

    const saveUser = () => {
        setBackdropOpened(true);

        if(!createMode) // edit mode
        {
          updateUser(data.email, data, () => {
              fetchUsers(setUsers);
              onDialogClose();
              setBackdropOpened(false);
          });
        }
        else // create mode
        {
          createNewUser(data, () => {
              fetchUsers(setUsers);
              onDialogClose();
              setBackdropOpened(false);
          });
        }
    }; 


    return (
        <Dialog fullWidth={true} onClose={onDialogClose} open={true}>
            <Box backgroundColor={colors.primary[400]} display="flex" alignItems="start" justifyContent="space-between">
                <Header title={user ? `Edit ${user.displayName}` : `Create New User`} />
            </Box>
            <List disablePadding={true}>
                {renderDialogElements()}
            </List>
            <Button variant="contained" color="secondary" disabled={saveDisabled} onClick={saveUser}>Save</Button>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpened}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    );
};

UserForm.propTypes = {
    games: PropTypes.object,
    gameRoles: PropTypes.object,
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    setUsers: PropTypes.any,
    editedUser: PropTypes.instanceOf(UserDto),
    updateEditedUser: PropTypes.any,
    onDialogClose: PropTypes.any,
    editedUserEmail: PropTypes.any,
    backdropOpened: PropTypes.any,
    setBackdropOpened: PropTypes.any,
    roles: PropTypes.array,
    user: PropTypes.object,
    environments: PropTypes.array
};

export const propNames = {
  DISPLAY_NAME: 'displayName',
  EMAIL: 'email',
  SYSTEM_ADMIN: 'systemAdmin',
  CID: 'cid',
  ROLE: 'role'
};

export default UserForm;