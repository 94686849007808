import * as React from 'react';

import { Grid, Box, Typography, useTheme, Dialog, Button } from "@mui/material";
import { tokens } from "../theme";

import Header from "../components/header";
import SwitchBox from "../components/switch";
import SelectBox from "../components/select";
import TextBox from "../components/text";
import Compound from "../components/compound";
import NumberBox from "../components/number";
import ActionButton from "../components/actionButton";

import { apiCall } from "../utils/network";
import { Permissions, InputDataTypes } from 'shared';
import { hasPermission } from "shared/src/utils/PermissionsUtils.js"; 
import { FeatureTypes } from 'shared/src/constants/featureTypes';
import { getRowValuesGrid } from './featurePage';
import PropTypes from "prop-types";

const BasicComponentsPage = ({configurationsPage, user, gameKey, env, configs, setBackdropOpened, backdropOpened, title, subtitle}) => {

    const [featureValues, setFeatureValues] = React.useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [configResponseData, setConfigResponseData] = React.useState(null);

    const fetchData = () => {
        setBackdropOpened(true);
        const params = {};

        if(configs && configs[0] && configs[0].subSection)
            params.subSection = configs[0].subSection;

            apiCall({
                method: 'GET',
                api: "configs",
                environment: env,
                gameKey,
                params: params
            }).then(values => {
                setFeatureValues(values);
                setBackdropOpened(false);
            }).catch(e => { console.log(e.message); setBackdropOpened(false); });
    };

    React.useEffect(() => { 
        if (configurationsPage) {
            fetchData(); 
        }
    }, [gameKey, env, setBackdropOpened, setFeatureValues]);

    const renderBasicComponent = (config) => {
        
            let editMode = hasPermission(user, configurationsPage ? Permissions.EditConfigs : Permissions.SupportActions, gameKey, env);
            
            const externalHandleChange = async (value, callback) => {
                
                try {
                    setBackdropOpened(true);
        
                    // basic components don't hold field id so this method prepare this for them. compound has it so we pass the value as is
                    if (config.fields.length === 1) // not compound - simple feature
                        value = [{id: config.fields[0].id, value: value}];
        
                    let payload = {id: config.id, values: value};
                    let data = await apiCall({
                        method: 'POST',
                        api: configurationsPage ? 'configs' : 'support',
                        environment: env,
                        gameKey: gameKey,
                        body: payload
                    });
        
                    if (callback)
                        callback();
        
                    if(configurationsPage)
                        fetchData();
                    
                    if(data !== null && (config.type === FeatureTypes.READ || config.type === FeatureTypes.UPDATE)){
                        setConfigResponseData(data);
                    }   
        
                    setBackdropOpened(false);
                }
                catch (e) {
                    setBackdropOpened(false);
                }
            };
        
            // prepare the values (including defaults)
            let values = featureValues.find(x => x.featureId === config.id)?.values ?? [];
            config.fields.forEach(field => {
                if(field.defaultValue !== undefined && field.defaultValue !== null && !values.find(x => x.id === field.id)){
                    values.push({id: field.id, value: field.defaultValue});
                }
            });

            let innerComponent;
            if (config.fields.length > 1) { // compound configuration has more than 1 field
        
                innerComponent = (<Compound
                    colors={colors}
                    feature={config}
                    featureValues={values?.map(x => { return {id: x.id.toString(), value: x.value}; })} // make sure ids are strings
                    externalHandleChange={externalHandleChange}
                    setBackdropOpened={setBackdropOpened}
                    backdropOpened={backdropOpened}
                    editMode={editMode}/>);
            } 
            else {
                let value = "";
                if (values?.length > 0) {
                    value = values[0].value;
                }
        
                let type = config.fields[0].type;
                switch (type) {
                    case InputDataTypes.API_BUTTON:
                        {
                            innerComponent = (<ActionButton
                                buttonText={config.fields[0].buttonText}
                                independent={true}
                                externalHandleChange={externalHandleChange}
                                editMode={editMode}/>);
                            break;
                        }
                    case InputDataTypes.STRING: 
                    case InputDataTypes.STORAGE_FOLDER: 
                    {
                        innerComponent = (<TextBox
                            id={config.id.toString()}
                            currentValue={value}
                            independent={true}
                            externalHandleChange={externalHandleChange}
                            editMode={editMode}/>);
                        break;
                    }
                    case InputDataTypes.PHONE_NUMBER:
                    case InputDataTypes.INTEGER: 
                    {
                        innerComponent = (<NumberBox
                            id={config.id.toString()}
                            currentValue={value.toString()}
                            min={config.fields[0].minValue}
                            max={config.fields[0].maxValue}
                            independent={true}
                            setBackdropOpened={setBackdropOpened}
                            externalHandleChange={externalHandleChange}
                            editMode={editMode}/>);
                        break;
                    }
                    case InputDataTypes.BOOL: 
                    {
                        innerComponent = (<SwitchBox
                            id={config.id.toString()}
                            currentValue={value}
                            independent={true}
                            externalHandleChange={externalHandleChange}
                            setBackdropOpened={setBackdropOpened}
                            editMode={editMode}/>);
                        break;
                    }
                    case InputDataTypes.SINGLE_SELECT: 
                    {   
                        innerComponent = (<SelectBox
                            id={config.id.toString()}
                            currentValue={value}
                            options={config.fields[0].options}
                            externalHandleChange={externalHandleChange}
                            setBackdropOpened={setBackdropOpened}
                            editMode={editMode}/>);
                        break;
                    }
                    default: {
                        console.log(`Unsupported general feature with id ${config.id}`);
                        break;
                    }
                }
            }
        
            return (<Box
                key={config.id}
                gridColumn="span 3"
                backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="start"
                justifyContent="space-between">
                <Box mb="30px">
                    <Typography
                        variant="h4"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{m: "0 0 5px 0"}}
                    >
                        {config.displayName}
                    </Typography>
                    <Typography variant="h5" color={colors.greenAccent[400]}>
                        {config.description}
                    </Typography>
                </Box>
                <Typography
                    fontStyle="italic"
                    fontWeight="bold"
                    sx={{fontSize: 'h1.fontSize', color: colors.grey[100]}}
                />
                <Grid container justifyContent="end">
                    {innerComponent}
                </Grid>
            </Box>);
    };

    const renderBasicComponentList = () => {
        
        const res = [];
        if (configs) {
            configs.forEach(config => {
                res.push(renderBasicComponent(config));
            });
        }

        return res;
    };

    const renderConfigResponseData = () => {
        if (!configResponseData) {
            return null;
        }
        
        return (
            <Dialog fullWidth={true} onClose={() => {}} open={true}>
                <Box backgroundColor={tokens(theme.palette.mode).primary[400]} display="flex" alignItems="start"
                     justifyContent="space-between">
                    <Header title={configResponseData.displayName}/>
                </Box>
                {getRowValuesGrid(configResponseData)}
                <Button variant="contained" color="secondary" onClick={() => {
                    setConfigResponseData(null);
                }}>Close</Button>
            </Dialog>);
    };

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={title} subtitle={subtitle}/>
            </Box>
                {renderConfigResponseData()}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            >
                {renderBasicComponentList()}
            </Box>
        </Box>
    );
};

BasicComponentsPage.propTypes = {
    setConfigResponseData: PropTypes.func,
    configurationsPage: PropTypes.bool,
    user: PropTypes.object,
    gameKey: PropTypes.string,
    env: PropTypes.string,
    configs: PropTypes.array,
    setBackdropOpened: PropTypes.func,
    backdropOpened: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string
};

export default BasicComponentsPage;
