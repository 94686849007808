import {toCamelCase} from "./string";
import {deleteGameKeyFromStorage, deleteUserAndAuthFromLocalStorage, getTokenFromLocalStorage} from "./localStorage";
import {EnvironmentsKeys, GamesKeys, HttpError, UserDto, isConfig} from "shared";

const authServerURL = process.env.REACT_APP_AUTH_SERVICE_URL;
const pokerURL = process.env.REACT_APP_API_POKER_SERVICE_URL;
const bingoURL = process.env.REACT_APP_API_BINGO_SERVICE_URL;

const envTemplateKey = "{env}";

function getApiEnv(envKey) {
    switch (envKey) {
        case EnvironmentsKeys.Dev:
            return "dev";
        case EnvironmentsKeys.Production:
            return "prod";
        case EnvironmentsKeys.LiveOps:
            return "liveops";
        case EnvironmentsKeys.QA:
            return "qa";
        default:
            throw new Error(`Unknown ${envKey}`);
    }
}

function getAuthEnv(envKey) {
    switch (envKey) {
        case EnvironmentsKeys.Dev:
            return "dev";
        default:
            return "prod";
    }
}

function getGameUrl(gameKey, env) {
    switch (gameKey) {
        case GamesKeys.PokerFace:
            return pokerURL.replace(envTemplateKey, getApiEnv(env));
        case GamesKeys.RoyalBingo:
            return bingoURL.replace(envTemplateKey, getApiEnv(env));
        default:
            throw new Error(`Unknown ${gameKey}`);
    }
}

function getOptions(method, contentType = 'application/json; charset=UTF-8') {

    let headers = {
        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
    };

    if (contentType)
        headers['Content-Type'] = contentType;

    return {
        method: method,
        headers: headers
    };
}

function authrizeResponse(response) {
    if (response.status === 401) {
        deleteUserAndAuthFromLocalStorage();
        deleteGameKeyFromStorage();
        window.location.href = '/';
    }
}

async function handleResponse(response) {
    authrizeResponse(response);

    const data = await response.json();

    if (!response.ok) {
        if (data.error) {
            const error = HttpError.createHttpError(data.error);
            console.error(error);
            return Promise.reject(error);
        } else {
            const error = new Error(data);
            console.error(error);
            return Promise.reject(error);
        }
    }

    return data;
}

function createURLParams(gameKey, environment, params) {
    const queryParam = new URLSearchParams({
        gameKey,
        environment
    });

    if (params) {
        for (const [key, value] of Object.entries(params)) {
            queryParam.append(key, value);
        }
    }

    return queryParam;
}

export const apiFileDownload = async (config) => {
    const {method, api, gameKey, environment, params} = config;
    const options = getOptions(method);
    const queryParam = createURLParams(gameKey, environment, params);

    const response = await fetch(`${getGameUrl(gameKey, environment)}/${api}?${queryParam.toString()}`, options);
    authrizeResponse(response);

    //get download filename
    let headerLine = response.headers.get('Content-Disposition');
    let startFileNameIndex = headerLine.indexOf('=') + 1;
    let filename = headerLine.substring(startFileNameIndex);

    // download file
    const blob = await response.blob();
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
};

export const apiFileUpload = async (config) => {

    const {api, gameKey, environment, file, params} = config;

    const formData = new FormData();
    formData.append(`file`, file, file.name);
    const options = getOptions('POST', null);
    options.body = formData;

    const queryParam = createURLParams(gameKey, environment, params);

    const response = await fetch(`${getGameUrl(gameKey, environment)}/${api}?${queryParam.toString()}`, options);
    return await handleResponse(response);
};

export const apiMultipleFilesUpload = async (config) => {

    const {api, gameKey, environment, files, params} = config;

    const formData = new FormData();

    let count = 0;

    for(const file of files) {
        formData.append(`file-${count}`, file, file.name);    
        count++;
    }
    
    const options = getOptions('POST', null);
    options.body = formData;

    const queryParam = createURLParams(gameKey, environment, params);

    const response = await fetch(`${getGameUrl(gameKey, environment)}/${api}?${queryParam.toString()}`, options);
    return await handleResponse(response);
};

export const apiCall = async (config) => {
    const {method, api, gameKey, environment, params, body} = config;
    let options = getOptions(method);

    if (body)
        options.body = JSON.stringify(body);

    const queryParam = createURLParams(gameKey, environment, params);

    const url = getGameUrl(gameKey, environment);

    const response = await fetch(`${url}/${api}?${queryParam.toString()}`, options);
    return await handleResponse(response);
};

export const apiAuthCall = async (config) => {
    const {method, api, environment, body} = config;
    let options = getOptions(method);

    if (body)
        options.body = JSON.stringify(body);

    const url = authServerURL.replace(envTemplateKey, getAuthEnv(environment));

    const response = await fetch(`${url}/${api}`, options);
    return await handleResponse(response);
};

export const getFeatureRouting = (feature) => {
    let str = isConfig(feature) ? "metadata" : toCamelCase(feature.displayName);

    if(feature.subSection)
        str = `${toCamelCase(feature.subSection)}-${str}`;

    return "/features/" + str;
};

// Users
export const fetchUsers = (callback) => {
    apiAuthCall({
        method: 'GET',
        api: "users"
    }).then(data => callback(data.map(user => UserDto.create(user))));
};

export function updateUser(userId, user, callback) {
    apiAuthCall({
        method: 'PUT',
        api: `users/${userId}`,
        body: user
    }).then(callback);
}

export function deleteUser(userId, callback) {
    apiAuthCall({
        method: 'DELETE',
        api: `users/${userId}`
    }).then(callback);
}

export function createNewUser(user, callback) {
    apiAuthCall({
        method: 'POST',
        api: `users`,
        body: user
    }).then(callback);
}