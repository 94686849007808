import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import List from '@mui/material/List';
import StepLabel from '@mui/material/StepLabel';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getInnerElement } from '../utils/componentHelper';
import { apiCall, getFeatureRouting } from "../utils/network";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Header from "./header";
import { Grid } from '@mui/material';
import { InputDataTypes } from 'shared';
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

export default function FeatureWizard({gameKey, env, feature, setBackdropOpened, backdropOpened}) {

    let complexFeatureRoute = getFeatureRouting/(feature);

    const steps = feature.wizardData.screens.map(screen => screen.title);
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    let navigate = useNavigate(); 

    const setSingleValue = (values, setValues, fieldId) => {
        
        return (fieldValue) => {
            let value = values.find(x => x.id === fieldId);
            
            if(value)
                value.value = fieldValue;
            else
                values.push({id: fieldId, value: fieldValue});

            setValues(values);
        };
    };

    const [values, setValues] = React.useState([]);

    const isStepOptional = (/*step*/) => {
        return false;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const mandatoryInputLeft = (/*step*/) => {
        return false;
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((step) => step + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((step) => step - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const innerProperties = [];
    feature.fields.forEach(field => 
    {
        if(field.wizardData && field.wizardData.screenId === activeStep + 1)
        {
            innerProperties.push(getInnerElement(field.id, field.type, null, setSingleValue(values, setValues, field.id), 
                colors, field.displayName, field.description, field.options, field.minValue, field.maxValue, field.wizardData.mandatory ?? false, true));
        }
    });

    const getSummaryFieldDisplayValue = (value, field) => {
        
        if(field.type === InputDataTypes.SINGLE_SELECT){
            return value.label;
        }

        return value;
    };

    const getSummaryProperties = () => {
        let res = [];
        values.forEach(valueObj => {
            
            let fieldId = valueObj.id;
            let value = valueObj.value;
            let field = feature.fields.find(x => x.id === fieldId);

            res.push(<Box borderTop={1} key={fieldId} backgroundColor={colors.primary[400]} alignItems="start">
                        <Box mb="10px">
                            <Typography variant="h4" color={colors.grey[100]} fontWeight="bold" sx={{ m: "0 0 5px 0" }}>
                                {field.displayName}
                            </Typography>
                        </Box>
                        <Typography fontStyle="italic" fontWeight="bold" sx={{ fontSize:'h1.fontSize', color: colors.grey[100] }}/>
                        <Grid container justifyContent="end">
                            {getSummaryFieldDisplayValue(value, field)}
                        </Grid>
                    </Box>
            );
        });  
        return res;
    };

    const getSummaryScreen = () => {
        return (<Dialog fullWidth={true} onClose={() => {navigate(complexFeatureRoute);}} open={true}>
                    <Box backgroundColor={colors.primary[400]} display="flex" alignItems="start" justifyContent="space-between">
                        <Header title={`Create ${feature.displayName} - Summary`} 
                            subtitle={`Please go over this summary and make sure all values are correct before creating.`} 
                        />
                    </Box>
                    <List disablePadding={true}>
                        {getSummaryProperties()}
                    </List>
                    <Button variant="contained" color="secondary" onClick={ async () => {
                        try {
                            console.log("click");
                            setBackdropOpened(true);
                            await apiCall({
                                method: 'POST',
                                api: "features",
                                environment: env,
                                gameKey,
                                body: values
                            });
                            setBackdropOpened(false);
                            navigate(complexFeatureRoute);
                        }
                        catch (e) {
                            setBackdropOpened(false);
                        }
                    }}>Save</Button>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={backdropOpened}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Dialog>);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdropOpened}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                        <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length 
            ? getSummaryScreen() 
            : (<React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                {innerProperties}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>Back</Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {isStepOptional(activeStep) && (<Button onClick={handleSkip} sx={{ mr: 1 }}>Skip</Button>)}
                    <Button color="inherit" disabled={mandatoryInputLeft(activeStep)} onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                </Box>
                </React.Fragment>
            )}
        </Box>
    );
}

FeatureWizard.propTypes = {
    gameKey: PropTypes.string,
    env: PropTypes.string,
    feature: PropTypes.object,
    setBackdropOpened: PropTypes.func,
    step: PropTypes.object,
    backdropOpened: PropTypes.bool
};