
export function toCamelCase(str) {
    return str.replace(/^\w|[A-Z]|\b\w/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

export function emailValid(str) {
  if(str)
  {
    let parts = str.split('@');
    if(parts.length === 2 && parts[1].includes(".")){
      let indexOfPoint = parts[1].indexOf('.');
      if(indexOfPoint > 0 && indexOfPoint < parts[1].length-1)
        return true;
    }
  }
  return false;
}

export function isNullOrEmpty(str) {
  if (str == null || str.trim() === ''){
      return true;
  }
  return false;
}

export function isNumeric(str) {
  if (typeof str != "string") // we only process strings!  
    return false;

  // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  return !isNaN(str) && !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
}
