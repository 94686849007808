export const Icons = {
    
    SUPPORT: "support",
    BASIC_CONFIGS: "basicConfigs",
    
    TABLE: "table",
    WHEEL: "wheel",
    NUMBER: "number",
    CASINO: "casino",
    CALENDAR: "calendar",
    NUMBERED_LIST: "numberedList",
    PEOPLE: "people",
    SHOPPING_CART: "shoppingCart",
    STATS: "stats",
    LOGIN: "login",
    DELETE: "delete",
    DEVICE: "device",
    DOLLAR_WITH_CIRCLE: "dollarWithCircle",
    PIG: "pig",
    DELETED_DEVICE: "deletedDevice",
    DEFAULT: "default",
    HOME: "home",
    PEOPLE_OUTLINED: "peopleOutlined",
    USERS: "users",
    MENU: "menu",
    BALANCE: "balance",
    UNDO: "undo",
    FLAG: "flag",
    PLAYERS: "players",
    MINUS_PLAYERS: "minusPlayers",
    CAROUSEL: "carousel",
    TASK: "task",
    ELEVATOR: "elevator",
    PLUS_MINUS: "plusMinus",
    IMAGES: "images",
    FOOTBALL: "football",
    DOOR: "door",
    DOLLAR: "dollar",
    VIDEO_SETTINGS: "videoSettings"
};