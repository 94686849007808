import { Grid, Box } from "@mui/material";
import Typography from '@mui/material/Typography';
import TextBox from '../components/text';
import NumberBox from '../components/number';
import SwitchBox from '../components/switch';
import MultiSelectBox from '../components/multiSelect';
import SelectBox from '../components/select';
import DateBox from '../components/date';
import { InputDataTypes } from "shared";

export function getInnerElement(id, valueType, currentValue, setSingleValue, colors, 
    displayName, description, options, minValue, maxValue, required, editMode){
        
    let strId = id.toString();
    let innerCurrentValue = currentValue;

    switch(valueType){
        case InputDataTypes.DATE: 
        {
            return wrap((<DateBox required={required} id={strId} currentValue={innerCurrentValue} setSingleValue={setSingleValue} independent={false} editMode={editMode} />), 
                colors, strId, displayName, description);
        }
        case InputDataTypes.JSON:
        case InputDataTypes.MULTI_SEGMENTS:
        {
            if(innerCurrentValue)
                innerCurrentValue = JSON.stringify(innerCurrentValue);
            else
                innerCurrentValue = [];

            return wrap((<TextBox required={required} id={strId} currentValue={innerCurrentValue} setSingleValue={setSingleValue} independent={false} editMode={editMode} />), 
                colors, strId, displayName, description);
        }
        case InputDataTypes.STRING_ARRAY:
        case InputDataTypes.STRING:
        {
            if(!innerCurrentValue)
                innerCurrentValue = "";

            return wrap((<TextBox required={required} id={strId} currentValue={innerCurrentValue} setSingleValue={setSingleValue} independent={false} editMode={editMode}/>), 
                colors, strId, displayName, description);
        }
        case InputDataTypes.SINGLE_SELECT:
        {
            return wrap((<SelectBox required={required} id={strId} currentValue={innerCurrentValue} options={options} setSingleValue={setSingleValue} editMode={editMode}/>),
                colors, strId, displayName, description);
        }
        case InputDataTypes.MULTI_SELECT:
        {
            return wrap((<MultiSelectBox required={required} id={strId} currentValue={innerCurrentValue} optionalValues={options} setSingleValue={setSingleValue} editMode={editMode}/>),
                colors, strId, displayName, description);
        }
        case InputDataTypes.INTEGER:
        case InputDataTypes.DECIMAL:
        {
            if(!innerCurrentValue)
                innerCurrentValue = 0;

            return wrap((<NumberBox required={required} id={strId} currentValue={innerCurrentValue.toString()} min={minValue} max={maxValue} independent={false} setSingleValue={setSingleValue} editMode={editMode}/>), 
                colors, strId, displayName, description);
        }
        case InputDataTypes.BOOL:
        {
            if(!innerCurrentValue)
                innerCurrentValue = false;

            return wrap((<SwitchBox key={strId} id={strId} currentValue={innerCurrentValue} setSingleValue={setSingleValue} editMode={editMode}/>), 
                colors, strId, displayName, description);
        }
        case InputDataTypes.INTEGER_ARRAY:
        {
            if(!innerCurrentValue)
                innerCurrentValue = "";

            if(!description)
                description = "Comma separated list of integers";

            return wrap((<TextBox required={required} id={strId} currentValue={innerCurrentValue} setSingleValue={setSingleValue} independent={false} editMode={editMode}/>), 
                colors, strId, displayName, description);
        }
        default:
            return <></>;
    }
}

export const wrap = (obj, colors, id, displayName, description) => {
    return (<Box borderTop={1} key={id} backgroundColor={colors.primary[400]} alignItems="start">
                <Box mb="10px">
                    <Typography variant="h4" color={colors.grey[100]} fontWeight="bold" sx={{ m: "0 0 5px 0" }}>
                        {displayName}
                    </Typography>
                    <Typography variant="h5" color={colors.greenAccent[400]}>
                        {description}
                    </Typography>
                </Box>
                <Typography fontStyle="italic" fontWeight="bold" sx={{ fontSize:'h1.fontSize', color: colors.grey[100] }}/>
                <Grid container justifyContent="end">
                    {obj}
                </Grid>
            </Box>);
};