import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, Box, Select, MenuItem, Chip, OutlinedInput } from "@mui/material";
import { propNames } from './userForm';
import PropTypes from "prop-types";

const UserFormRoleEnvPerGame = ({user, setUserProp, gameKey, allRoles, allEnvironments}) => {

    // Initialize state for the data object and the number of role/env elements
    let existingRoles = [];
    if(user){
        existingRoles = user?.games?.find(x => x.key === gameKey)?.roles ?? [];
    }

    const [roles, setRoles] = useState(existingRoles);
    const [numElements, setNumElements] = useState(existingRoles.length);

    // Handler for when the user changes the role select
    const handleRoleChange = (index, event) => {
        const newData = [...roles];
        newData[index].role = allRoles.find(x => x.displayName === event.target.value).key;
        setRoles(newData);
        setUserProp(propNames.ROLE, gameKey, newData);
    };

    // Handler for when the user changes the environments multi-select
    const handleEnvChange = (index, event) => {
        const newData = [...roles];
        newData[index].environments = Array.from(event.target.value, value => allEnvironments.find(x => x.displayName === value).key);
        setRoles(newData);
        setUserProp(propNames.ROLE, gameKey, newData);
    };

    // Handler for when the user clicks the 'REMOVE ROLE' button
    const handleRemoveRole = index => {
        const newData = [...roles];
        newData.splice(index, 1);
        setRoles(newData);
        setNumElements(numElements - 1);
        setUserProp(propNames.ROLE, gameKey, newData);
    };

    // Handler for when the user clicks the 'ADD ROLE' button
    const handleAddRole = () => {
        setRoles([...roles, { role: '', environments: [] }]);
        setNumElements(numElements + 1);
        setUserProp(propNames.ROLE, gameKey, roles);
    };

    let allRolesOptions = allRoles.map(x => <MenuItem key={x.key} value={x.displayName}>{x.displayName}</MenuItem>); 
    let allEnvOptions = allEnvironments.map(x => <MenuItem key={x.key} value={x.displayName}>{x.displayName}</MenuItem>); 
    
    return (
        <div>
            {/* Render one 'role/env element' for each item in the 'roles' array */}
            {roles.map((item, index) => (
                <div key={index}>
                    <Box>
                        <Select key='role' size='small' value={getRoleDisplayName(item.role, allRoles)} onChange={event => handleRoleChange(index, event)}>{allRolesOptions}</Select>
                        <Select key='envs' input={<OutlinedInput id="select-multiple-chip" label="Chip" />} 
                            size='small' multiple value={item.environments?.map(x => getEnvDisplayName(x, allEnvironments)) ?? []} onChange={event => handleEnvChange(index, event)}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                  ))}
                                </Box>
                              )}
                            >{allEnvOptions}</Select>
                        <Button key='remove' color='secondary' size="small" variant="contained" startIcon={<RemoveIcon />} onClick={() => handleRemoveRole(index)}>Remove</Button>
                    </Box>
                </div>
            ))}

            {/* Render the 'ADD ROLE' button */}
            <Button color='secondary' size="small" variant="contained" startIcon={<AddIcon />} onClick={handleAddRole}>Add Role</Button>
        </div>
    );
};

UserFormRoleEnvPerGame.propTypes = {
    user: PropTypes.object,
    setUserProp: PropTypes.func,
    gameKey: PropTypes.string,
    allRoles: PropTypes.array,
    allEnvironments: PropTypes.array
};

function getRoleDisplayName(roleKey, roles){
    return roles.find(x => x.key === roleKey)?.displayName ?? '';
}

function getEnvDisplayName(envKey, environments){
    return environments.find(x => x.key === envKey).displayName;
}

export default UserFormRoleEnvPerGame;