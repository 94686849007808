import {useState, useEffect} from 'react';
import {apiAuthCall} from '../utils/network';
import {useGoogleLogin} from '@react-oauth/google';
import {setUserAndTokenToLocalStorage} from '../utils/localStorage';
import PropTypes from "prop-types";

const LoginPage = ({setUser, env}) => {

    const [currentImageNumber, setCurrentImageNumber] = useState(1); // default is 1 (first image)

    const numOfImages = 17; // TODO move to process.env
    const getBackgroundImagePath = (number) => {
        return `/assets/background/${number}.jpg`;
    };

    const onGoogleLoginSuccess = async ({code}) => {
        const data = await apiAuthCall({
            method: 'POST',
            body: {code},
            api: "login",
            environment: env
        });
        setUser(data.user);
        setUserAndTokenToLocalStorage(data.token, data.user);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            let randomNumber = Math.floor(Math.random() * numOfImages) + 1;
            setCurrentImageNumber(randomNumber);
        }, 2000);
        return () => clearInterval(intervalId);
    }, [numOfImages]);

    const googleLogin = useGoogleLogin({
        onSuccess: onGoogleLoginSuccess,
        flow: 'auth-code',
        hosted_domain: "communix.com"
    });

    return (
        <div style={{
            backgroundImage: `url(${getBackgroundImagePath(currentImageNumber)})`,
            height: '100%',
            maxWidth: 'auto',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            objectFit: "fill"
        }}>
            <center>
                <button style={{height: "30px"}} onClick={() => googleLogin()}>{' '} 🚀 Sign in with Google 🚀 {' '}</button>
            </center>
        </div>);
};

LoginPage.propTypes = {
    setUser: PropTypes.func,
    env: PropTypes.string
};

export default LoginPage;