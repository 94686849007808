import * as React from 'react';
import { Grid, Button, FormLabel } from "@mui/material";
import PropTypes from "prop-types";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const DateBox = ({id, currentValue, independent, externalHandleChange, setSingleValue, required, editMode}) => {
  
    const [value, setValue] = React.useState(currentValue === undefined ? null : currentValue);
  
    React.useEffect(() => setValue(value), [value]);

    const handleChange = (x) => {
        let time = x[`$d`].getTime(); 

        console.log(time);
        if(setSingleValue)
            setSingleValue(time / 1000); // make it in seconds
        
        setValue(time);
    };

    return (<Grid container justifyContent="end">
                <FormLabel required={required}></FormLabel>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker id={id} key={id} onChange={(newValue) => handleChange(newValue)} value={value} />
                    {independent && editMode ? <Button variant="contained" color="secondary" onClick={() => externalHandleChange(value)}>Save</Button> : []}
                </LocalizationProvider>
            </Grid>);        
};

DateBox.propTypes = {
    id: PropTypes.string,
    required: PropTypes.bool,
    currentValue: PropTypes.string,
    independent: PropTypes.bool,
    externalHandleChange: PropTypes.func,
    setSingleValue: PropTypes.func,
    editMode: PropTypes.bool
};

export default DateBox;