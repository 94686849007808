import * as React from 'react';
import { Grid, MenuItem, Select, FormLabel } from "@mui/material";
import PropTypes from "prop-types";

const SelectBox = ({id, required, currentValue, options, externalHandleChange, setSingleValue, editMode}) => {
    
    let currentOption = null;
    
    if(currentValue)
        currentOption = options.find(x => x.label === currentValue);
    
    const [option, setOption] = React.useState(currentOption);
    React.useEffect(() => setOption(currentOption), [currentOption]);

    let menuOptions = [];
    options.forEach(x => {
        menuOptions.push(<MenuItem key={x.label} value={x.label}>{x.label}</MenuItem>);
    });

    const handleChange = (label) => {
        if(editMode){
            let option = options.find(x => x.label === label);
    
            if(externalHandleChange)
                externalHandleChange(option.value);
    
            if(setSingleValue)
                setSingleValue(option);
    
            setOption(option);
        }
    };
    
    return (<Grid container justifyContent="end">
                <FormLabel required={required}></FormLabel>
                <Select id={id} key={id} value={option?.label ?? ''} onChange={(event) => handleChange(event.target.value)}>{menuOptions}</Select>
            </Grid>);
};

SelectBox.propTypes = {
    id: PropTypes.string,
    required: PropTypes.bool,
    currentValue: PropTypes.string,
    options: PropTypes.array,
    externalHandleChange: PropTypes.func,
    setSingleValue: PropTypes.func,
    editMode: PropTypes.bool
};

export default SelectBox;


