import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {Box, useTheme, Button, Autocomplete, TextField, ButtonBase, Typography, Dialog, List } from "@mui/material";
import {tokens} from "../theme";
import Header from "../components/header";
import * as React from 'react';
import {apiCall, apiFileUpload} from '../utils/network';
import PropTypes from "prop-types";
import PublishIcon from '@mui/icons-material/Publish';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { styled } from '@mui/material/styles';
import { apiMultipleFilesUpload } from '../utils/network';
import {HttpError} from "shared";

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});

const ImagesPage = ({feature, gameKey, env, setBackdropOpened}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [folders, setFolders] = React.useState([]);
    const [inputFolder, setInputFolder] = React.useState("");
    const [files, setImages] = React.useState([]);
    
    // This is used to handle errors after file upload
    const [errors, setErrors] = React.useState([]);

    React.useEffect(() => {
            fetchData(gameKey, env, feature.id, setBackdropOpened, setFolders);
        }, [gameKey, env, feature, setBackdropOpened], setFolders
    );
    
    const uploadFolder = async (files) => {
        setBackdropOpened(true);
        let params = {
            featureId: feature.id
        };

        if(inputFolder)
            params.subFolder = inputFolder;

        try {
            await apiMultipleFilesUpload({
                api: "images/upload",
                gameKey,
                environment: env,
                params,
                files: files
            });
        } catch (error) {
            if (error instanceof HttpError && error.extraData) {
                setErrors({
                    title: error.message,
                    errors: error.extraData.map(x => `${x.location} | ${x.message}`)
                });
            } else {
                setErrors({
                    title: error.message,
                    errors: [error.message]
                });
            }
        }
        await fetchData(gameKey, env, feature.id, setBackdropOpened, setFolders);
        setInputFolder("");
        setBackdropOpened(false);
    };

    const replaceFile = async (subFolder, fileName, file) => {
        setBackdropOpened(true);
        let params = {
            subFolder: subFolder,
            fileName: fileName,
            featureId: feature.id
        };

        try {
            await apiFileUpload({
                api: "images/replace",
                gameKey,
                environment: env,
                file: file,
                params,
            });
        } catch (error) {
            if (error instanceof HttpError && error.extraData) {
                setErrors({
                    title: error.message,
                    errors: error.extraData.map(x => `${x.location} | ${x.message}`)
                });
            } else {
                setErrors({
                    title: error.message,
                    errors: [error.message]
                });
            }
        }
        await fetchData(gameKey, env, feature.id, setBackdropOpened, setFolders);
        setBackdropOpened(false);
    };

    const renderErrorsDialog = () => {
        
        if (errors.length === 0)
            return null;

        const getErrorsGrid = (errors) => {
            let res = [];
            let count = 1;
            errors.forEach(error => {
                res.push(
                    <Box borderTop={1} key={'error_' + count} backgroundColor={colors.primary[400]} alignItems="start">
                        <Box mb="10px">
                            <Typography variant="h4" color={colors.grey[100]} sx={{m: "0 0 5px 0"}}>
                                {error}
                            </Typography>
                        </Box>
                        <Typography fontStyle="italic" fontWeight="bold" sx={{fontSize: 'h1.fontSize', color: colors.grey[100]}}/>
                    </Box>
                );
                count++;
            });
            return res;
        };
        
        return (
            <Dialog fullWidth={true} onClose={() => {
            }} open={true}>
                <Box backgroundColor={colors.primary[400]} display="flex" alignItems="start"
                     justifyContent="space-between">
                    <Header title="Failed uploading images folder!!!"/>
                </Box>
                <List disablePadding={true}>
                    {getErrorsGrid(errors.errors, colors)}
                </List>
                <Button variant="contained" color="secondary" onClick={() => setErrors([])}>Close</Button>
            </Dialog>);
    };

    const renderUploadButton = () => {
        if(!feature.wizardData?.uploadAllowed)
            return null;

        return <Button
                    component="label"
                    key={'upload'}
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        border: 1, borderRadius: '10%', borderColor: colors.primary[400]
                    }}>
                    <PublishIcon sx={{mr: "10px"}}/>
                    Import to Folder: 
                    <input hidden accept=".png" type="file" webkitdirectory="" onChange={(event) => uploadFolder(event.target.files) }/>
                    <input id="txt" required value={inputFolder} onChange={(event) => setInputFolder(event.target.value)}/>
                </Button>;
    };
    
    const renderImages = () => {
        if(files.length > 0){
            
            let rows = [];
            files.forEach(file => {
                if(file.name){
                    rows.push(<Grid key={file.name} container spacing={2}>
                        <Grid item>
                            <ButtonBase sx={{ width: 128, height: 128 }}>
                            <Img id={file.name} alt="complex" src={file.path} onClick={() => window.open(`${file.path}`)}/>
                            </ButtonBase>
                        </Grid>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="subtitle1" component="div">{file.type} • {file.size}</Typography>
                                    <Typography variant="body2" color="text.secondary">Name: {file.name}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ButtonBase sx={{ width: 128, height: 128 }}>
                                <Button
                                    component="label"
                                    key={'replace'}
                                    sx={{
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.grey[100],
                                        borderRadius: '10%', 
                                    }}>
                                    <UpgradeIcon sx={{mr: "10px"}}/>
                                    Replace
                                    <input hidden accept=".png" type="file" onChange={(event) => replaceFile(file.subFolder, file.name, event.target.files[0]) }/>
                                </Button>
                            </ButtonBase>
                        </Grid>
                    </Grid>);
                }
            });

            if(rows.length === 0)
                return null;

            return (
                <Box m="20px"> 
                    <Paper
                        sx={{
                        p: 2,
                        margin: 'auto',
                        maxWidth: 500,
                        flexGrow: 1,
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                        }}
                    >
                        {rows}
                    </Paper>
                </Box>
                );
        }
        return null;
    };

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={feature.displayName} subtitle={feature.description}/>
                <Box>
                    {renderUploadButton()}
                </Box>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                    ".MuiDataGrid-aggregationColumnHeaderLabel": {
                        display: "none"
                    }
                }}
            >
            <Autocomplete
                disablePortal
                id="images-select"
                options={folders}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Folder" />}
                onChange={(event, newValue) => {
                    setBackdropOpened(true);
                    setImages([]);
                    apiCall({
                        method: 'GET',
                        api: 'images/urls',
                        gameKey,
                        environment: env,
                        params: {
                            subFolder: newValue,
                            featureId: feature.id
                        }
                    }).then(data => {
                        setImages(data);
                        setBackdropOpened(false);
                    }).catch(e => {
                        console.log(e.message);
                        setBackdropOpened(false);
                    });
                }}
            />
            {renderImages()}
            {renderErrorsDialog()}
            </Box>
        </Box>);
};

async function fetchData(gameKey, env, featureId, setBackdropOpened, setFolders){
    try {
        setBackdropOpened(true);
        const params = {
            featureId: featureId
        };
        apiCall({
            method: 'GET',
            api: 'images',
            gameKey,
            environment: env,
            params
        }).then(data => {
            setFolders(data);
            setBackdropOpened(false);
        }).catch(e => {
            console.log(e.message);
            setBackdropOpened(false);
        });
    } catch (error) {
        console.log('failed fetching image folders data', error.message);
    }
}

ImagesPage.propTypes = {
    gameKey: PropTypes.string,
    env: PropTypes.string,
    setBackdropOpened: PropTypes.func,
    uploadAllowed: PropTypes.bool,
    feature: PropTypes.object
};

export default ImagesPage;
