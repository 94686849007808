import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {apiCall} from '../utils/network';
import * as React from 'react';
import PropTypes from "prop-types";

const GameSelector = ({user, gameKey, setGameKey, env, setStartupData, setBackdropOpened}) => {

    const handleClick = async (gameKey) => {
        try {
            setBackdropOpened(true);
            setGameKey(gameKey);
            const data = await apiCall({
                method: 'GET',
                api: "startup",
                gameKey,
                environment: env
            });
            setStartupData(data);
            setBackdropOpened(false);
        }
        catch (e) {
            setBackdropOpened(false);
        }
    };

    return (
        <Dialog
            open={gameKey === null || gameKey === undefined}
            onClose={() => handleClick(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Welcome to Communix Backoffice!</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please choose your game.
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{justifyContent: 'space-around', alignItems: "center"}}>
                {gameButtons(user, handleClick)}
            </DialogActions>
        </Dialog>
    );
};

const gameButtons = (user, handleClick) => {
    let gameButtonsRes = [];
    user.games.forEach(game => {
        gameButtonsRes.push(<img
            key={game.key}
            alt=""
            src={`/assets/${game.key}.png`}
            style={{border: "5px solid #555", cursor: 'pointer', maxWidth: "30%"}}
            onClick={() => handleClick(game.key)}
        />);
    });

    return [gameButtonsRes];
};

GameSelector.propTypes = {
    user: PropTypes.object,
    gameKey: PropTypes.string,
    setGameKey: PropTypes.func,
    env: PropTypes.string,
    setBackdropOpened: PropTypes.func,
    setStartupData: PropTypes.func
};

export default GameSelector;
