import * as React from 'react';
import { Grid, MenuItem, Select, FormLabel } from "@mui/material";
import PropTypes from "prop-types";

const MultiSelectBox = ({id, required, currentValues, optionalValues, externalHandleChange, setSingleValue, editMode}) => {
    
    let currentOptions = [];

    if(currentValues)
        currentOptions = options.filter(x => currentValues.include(x.value))?.map(x => x.label) ?? [];
    
    const [options, setOptions] = React.useState(currentOptions);

    let menuOptions = [];
    optionalValues.forEach(option => {
        menuOptions.push(<MenuItem key={option.value} value={option.label}>{option.label}</MenuItem>);
    });

    const handleChange = (label) => {
        if(editMode){
            let option = optionalValues.find(x => x.label === label);
            options.push(option.label);
            if(externalHandleChange) // TODO
                externalHandleChange(options);
    
            if(setSingleValue) // TODO
                setSingleValue(options);
    
            setOptions(options);
        }
    };

    return (<Grid container justifyContent="end">
                <FormLabel required={required}></FormLabel>
                <Select multiple id={id} key={id} value={options ?? []} onChange={(event) => handleChange(event.target.value)}>{menuOptions}</Select>
            </Grid>);
};

MultiSelectBox.propTypes = {
    id: PropTypes.string,
    required: PropTypes.bool,
    currentValues: PropTypes.array,
    optionalValues: PropTypes.array,
    externalHandleChange: PropTypes.func,
    setSingleValue: PropTypes.func,
    editMode: PropTypes.bool
};

export default MultiSelectBox;


