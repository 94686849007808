import {GameRoles, SysAdminPermissions, Roles, Permissions} from "../constants/roles.js";

export const hasPermission = (user, permission, game, env) => {
    // SysAdmin Permissions require specific handling
    if (Object.values(SysAdminPermissions).includes(permission)) {
        if(user.systemAdmin)
            return true;
        else
            return false;
    }

    const userGameRoles = user.games.find(userGame => userGame.key === game);

    if (!userGameRoles) {
        return false;
    }

    if(permission === Permissions.View) // View end points are allowed to any user that has access to the game
        return true; 
        
    return userGameRoles.roles.some(userGameRole => {
        const roleHasPermission = GameRoles[userGameRole.role]?.permissions.includes(permission) || userGameRole.role === Roles.Admin;
        return roleHasPermission && userGameRole.environments.includes(env);
    });
};

export const hasOneOfThePermissions = (user, permissions, game, env) => {
    return permissions.some(permission => hasPermission(user, permission, game, env));
};

export const hasRole = (user, game, env) => {
    const userGameRoles = user.games.find(userGame => userGame.key === game);

    if (userGameRoles) {
        return userGameRoles.some(userGameRole => userGameRole.role.environments.includes(env))
    }

    return false;
}