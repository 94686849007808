import * as React from 'react';
import { Grid, Button, TextField, FormLabel } from "@mui/material";
import { isNumeric } from '../utils/string';
import PropTypes from "prop-types";

const NumberBox = ({id, required, currentValue, min, max, independent, externalHandleChange, setSingleValue, editMode}) => {
    
    const [value, setValue] = React.useState(currentValue);
  
    React.useEffect(() => setValue(currentValue), [currentValue]);

    const withinRange = (num, min, max) => {
        if(min && parseInt(num) < min)
            return false;

        if(max && parseInt(num) > max)
            return false;
        
        return true;
    };

    const handleChange = (x) => {
        if(editMode && isNumeric(x) && withinRange(x, min, max)) {
            if(setSingleValue)
                setSingleValue(x);
    
            setValue(x);
        }
    };

    return (<Grid container justifyContent="end">
                <FormLabel required={required}></FormLabel>
                <TextField id={id} key={id} type="number" onChange={(event) => handleChange(event.target.value)} value={value} />
                {independent && editMode ? <Button variant="contained" color="secondary" onClick={() => externalHandleChange(value)}>Save</Button> : []}
            </Grid>);        
};

NumberBox.propTypes = {
    id: PropTypes.string,
    required: PropTypes.bool,
    currentValue: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    independent: PropTypes.bool,
    externalHandleChange: PropTypes.func,
    setSingleValue: PropTypes.func,
    editMode: PropTypes.bool
};

export default NumberBox;