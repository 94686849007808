import {useState} from "react";
import {Routes, Route} from "react-router-dom";
import * as React from 'react';

import Topbar from "./scenes/topbar";
import Sidebar from "./scenes/sidebar";
import Dashboard from "./scenes/dashboard";
import UsersPage from "./scenes/usersPage";
import BasicComponentsPage from "./scenes/basicComponentsPage";
import GameSelector from "./scenes/gameSelector";
import FeaturePage from "./scenes/featurePage";
import LoginPage from "./scenes/loginPage";
import PrivateRoute from "./components/privateRoute";
import ImagesPage from "./scenes/imagesPage";

import {ColorModeContext, useMode} from "./theme";
import {sectionTypes} from "./constants/sectionTypes";
import {getFeatureRouting} from "./utils/network";
import {getUserFromLocalStorage, getEnvFromLocalStorage, setEnvKeyInLocalStorage} from "./utils/localStorage";

import {CssBaseline, ThemeProvider} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import {EnvironmentsKeys, isComplexFeature, isConfig, isImagesConfig, isSupportAction} from "shared";
import {LicenseInfo} from "@mui/x-license-pro";
import {getGameKeyFromStorage, setGameKeyInStorage} from "./utils/localStorage";
import {apiCall} from "./utils/network";
import { FeatureTypes } from "shared/src/constants/featureTypes";

function App() {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PREMIUM_KEY);
    const [theme, colorMode] = useMode();
    const [setIsSidebar] = useState(true);
    const [selectedSideBarElement, setSelectedSideBarElement] = useState("Dashboard");
    const [selectedSideBarSection, setSelectedSideBarSection] = useState(null);
    const [backdropOpened, setBackdropOpened] = React.useState(false);

    const [user, setUser] = React.useState(getUserFromLocalStorage());
    const [env, setEnv] = React.useState(getEnvFromLocalStorage() ?? EnvironmentsKeys.Dev);

    const [startupData, setStartupData] = React.useState([]);
    const [gameKey, setGameKey] = React.useState(getGameKeyFromStorage(user));
    
    React.useEffect(() => {
        if (gameKey) {
            setBackdropOpened(true);
            apiCall({
                method: 'GET',
                api: "startup",
                environment: env,
                gameKey
            }).then(data => {
                setStartupData(data);
                setBackdropOpened(false);
            }).catch(e => {
                setBackdropOpened(false);
            });
        }
    }, [gameKey, env, setStartupData, setBackdropOpened]);

    if (!user)
        return (<LoginPage setUser={setUser} env={env}/>);

    if (!gameKey)
        return (<GameSelector user={user} gameKey={gameKey}
                              setGameKey={(gameKey) => setGameKeyInStorage(setGameKey, gameKey)} env={env}
                              setStartupData={setStartupData} setBackdropOpened={setBackdropOpened}/>);

    let complexFeatures = startupData.features?.filter(x => [sectionTypes.FEATURES, sectionTypes.DATA].includes(x.section))?.filter(x => isComplexFeature(x)) ?? [];
    let complexFeaturesMetadataAndImages = startupData.features?.filter(x => x.section === sectionTypes.FEATURES && x.subSection)?.filter(x => isConfig(x) || isImagesConfig(x)) ?? [];    

    let basicConfigs = startupData.features?.filter(x => x.section === sectionTypes.FEATURES && !x.subSection)?.filter(x => isConfig(x)) ?? [];
    let basicSupportActions = startupData.features?.filter(x => x.section ===  sectionTypes.SUPPORT)?.filter(x => isSupportAction(x)) ?? [];

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <div className="app">
                    <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={backdropOpened}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <Sidebar gameKey={gameKey} env={env} user={user} complexFeatures={complexFeatures.concat(complexFeaturesMetadataAndImages)} selected={selectedSideBarElement}
                        setSelected={setSelectedSideBarElement} selectedSection={selectedSideBarSection} setSelectedSection={setSelectedSideBarSection}/>
                    <main className="content">
                        <Topbar setStartupData={setStartupData} user={user} env={env} setEnv={(envKey) => { setEnvKeyInLocalStorage(setEnv, envKey); }} gameKey={gameKey}
                                setGameKey={(gameKey) => setGameKeyInStorage(setGameKey, gameKey)}
                                setIsSidebar={setIsSidebar} setBackdropOpened={setBackdropOpened} />
                        <Routes>
                            <Route key="/" path="/" element={<PrivateRoute user={user} child={<Dashboard/>}></PrivateRoute>}/>
                            {user.systemAdmin ? <Route key="users" path="/users" element={<PrivateRoute user={user}
                                                                                                        child={
                                                                                                            <UsersPage
                                                                                                                games={startupData.games}
                                                                                                                roles={startupData.roles}
                                                                                                                environments={startupData.environments}
                                                                                                                backdropOpened={backdropOpened}
                                                                                                                setBackdropOpened={setBackdropOpened}/>}></PrivateRoute>}/> : <></>}
                            <Route key="basic-configurations" path="/basic-configurations" element={<PrivateRoute user={user}
                                        child={<BasicComponentsPage configurationsPage={true} type={FeatureTypes.CONFIG} fetchData={true} theme={theme}
                                                                    user={user} gameKey={gameKey}
                                                                    env={env} configs={basicConfigs}
                                                                    setBackdropOpened={setBackdropOpened}
                                                                    backdropOpened={backdropOpened}
                                                                    title={"Basic Configurations"}
                                                                    subtitle={"Welcome to basic configurations page"}/>}></PrivateRoute>}/>
                            <Route key="basic-support-actions" path="/basic-support-actions" element={<PrivateRoute user={user}
                                        child={<BasicComponentsPage configurationsPage={false} theme={theme}
                                                                    user={user} gameKey={gameKey}
                                                                    env={env}
                                                                    configs={basicSupportActions}
                                                                    setBackdropOpened={setBackdropOpened}
                                                                    backdropOpened={backdropOpened}
                                                                    title={"Basic Actions"}
                                                                    subtitle={"Welcome to basic actions page"}/>}></PrivateRoute>}/>
                            {getFeaturesRouting(complexFeatures, backdropOpened, setBackdropOpened, theme, gameKey, env, user)}
                            {getFeaturesMetadataAndImagesRouting(complexFeaturesMetadataAndImages, backdropOpened, setBackdropOpened, gameKey, env, user)}
                        </Routes>
                    </main>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>);
}

const getFeaturesMetadataAndImagesRouting = (metadataConfigs, backdropOpened, setBackdropOpened, gameKey, env, user) => {
    let res = [];
    let alreadySeenFeatures = [];
    if (metadataConfigs) {
        metadataConfigs.forEach(feature => {

            let path = getFeatureRouting(feature);
            if(isImagesConfig(feature)){
                res.push(<Route key={'route_features_' + feature.id} path={path}
                                element={<PrivateRoute user={user} child={<ImagesPage feature={feature} 
                                                                                        gameKey={gameKey} env={env} 
                                                                                        setBackdropOpened={setBackdropOpened}/>}>
                                </PrivateRoute>}/>);
            }
            else{
                if(alreadySeenFeatures.includes(feature.subSection))
                    return;
                
                res.push(<Route key={'route_features_' + feature.subSection + '_metadata'} path={path}
                            element={<PrivateRoute user={user} child={<BasicComponentsPage configurationsPage={true} 
                                                                        user={user} gameKey={gameKey}
                                                                        env={env} configs={metadataConfigs.filter(x => x.subSection === feature.subSection)}
                                                                        setBackdropOpened={setBackdropOpened}
                                                                        backdropOpened={backdropOpened}
                                                                        title={`${feature.subSection} Metadata`}
                                                                        subtitle={`Welcome to ${feature.subSection} Metadata page`}/>}>
                                </PrivateRoute>}/>);
            
                alreadySeenFeatures.push(feature.subSection);
            }
        });
    }
    return res;
}

const getFeaturesRouting = (features, backdropOpened, setBackdropOpened, theme, gameKey, env, user) => {
    let res = [];
    if (features) {
        features.forEach(feature => {

            let path = getFeatureRouting(feature);

            
            res.push(<Route key={'route_features_' + feature.id} path={path}
                            element={<PrivateRoute user={user} child={<FeaturePage key={'features_' + feature.id}
                                                                                   backdropOpened={backdropOpened}
                                                                                   gameKey={gameKey} env={env}
                                                                                   theme={theme} feature={feature}
                                                                                   setBackdropOpened={setBackdropOpened}/>}>
                            </PrivateRoute>}/>);
        
        });
    }
    return res;
};

export default App;
