export class HttpError extends Error {

    code;

    extraData;

    constructor(code, message, extraData) {
        super(message);
        this.code = code;
        this.extraData = extraData;
    }

    static createHttpError(obj){
        return new HttpError(obj.code, obj.message, obj.extraData);
    }
}

export class UnauthorizedError extends HttpError {
    constructor(message, extraData) {
        super(401, message, extraData);
    }
}

export class ServerError extends HttpError {
    constructor(message, extraData) {
        super(500, message, extraData);
    }
}

export class BadRequestError extends HttpError {
    constructor(message, extraData) {
        super(400, message, extraData);
    }
}

export class ForbiddenError extends HttpError {
    constructor(message, extraData) {
        super(403, message, extraData);
    }
}

export class NotFoundError extends HttpError {
    constructor(message, extraData) {
        super(404, message, extraData);
    }
}