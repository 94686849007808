export const Permissions = {
    View : "view",
    EditConfigs : "editConfigs",
    EditFeatures : "editFeatures",
    EditSegments : "editSegments",
    SupportActions : "supportActions"
};

export const SysAdminPermissions = {
    UserManagement : "userManagement"
};

// roles keys
export const Roles = {
    Admin: "admin",
    LiveOps : "liveOps",
    Support : "support",
    Viewer : "viewer"
};

export const GameRoles = {
    [Roles.Admin]: {
        displayName: "Admin",
        description: "Can edit everything",
        permissions: [Permissions.View, Permissions.EditConfigs, Permissions.EditFeatures, Permissions.EditSegments, Permissions.SupportActions]
    },
    [Roles.LiveOps]: {
        displayName: "Live Ops",
        description: "Can edit configs, features and segments",
        permissions: [Permissions.View, Permissions.EditConfigs, Permissions.EditFeatures, Permissions.EditSegments]
    },
    [Roles.Support]: {
        displayName: "Support",
        description: "Can access support data and actions",
        permissions: [Permissions.View, Permissions.SupportActions]
    },
    [Roles.Viewer]: {
        displayName: "Viewer",
        description: "Read-only",
        permissions: [Permissions.View]
    }
};